<template>
  <div @click="$emit('change', $event)">
    <span>
      <v-icon v-if="showDoubleArrows">
        {{ getArrowIcon }}
      </v-icon>
      {{ collapseText }}
      <v-icon>
        {{ getArrowIcon }}
      </v-icon>
    </span>
  </div>
</template>
<script>
export default {
  name: 'ProjectDetailsSummaryActionBlock',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showDoubleArrows: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    collapseText() {
      return this.value ? 'Collapse' : 'Expand for more information';
    },
    getArrowIcon() {
      const { value: val } = this;
      return val ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    },
  },
};
</script>
